<template>
  <div class="developer-playground">
    <div class="header">
      <h1>Developer Playground</h1>
      <p class="subtitle">Test and debug components in an isolated environment</p>
    </div>

    <div class="test-sections">
      <!-- UI Components Section -->
      <div class="section">
        <h2>UI Components</h2>
        <div class="cards">
          <router-link :to="{ name: 'dialog-test' }" class="test-card">
            <div class="card-content">
              <h3>Dialog Components</h3>
              <p>Test and preview all dialog variants with different button styles</p>
            </div>
            <div class="card-arrow">
              <v-icon>mdi-arrow-right</v-icon>
            </div>
          </router-link>

          <router-link :to="{ name: 'button-showcase' }" class="test-card">
            <div class="card-content">
              <h3>Button Components</h3>
              <p>Explore button styles, states, and usage patterns</p>
            </div>
            <div class="card-arrow">
              <v-icon>mdi-arrow-right</v-icon>
            </div>
          </router-link>

          <router-link :to="{ name: 'form-components' }" class="test-card">
            <div class="card-content">
              <h3>Form Components</h3>
              <p>Explore form inputs, validation, and styling patterns</p>
            </div>
            <div class="card-arrow">
              <v-icon>mdi-arrow-right</v-icon>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Features Section -->
      <div class="section">
        <h2>Features</h2>
        <div class="cards">
          <div class="test-card coming-soon">
            <div class="card-content">
              <h3>Authentication Flow</h3>
              <p>Coming soon - Test user registration, login, and password reset flows</p>
            </div>
            <div class="card-label">Coming Soon</div>
          </div>

          <div class="test-card coming-soon">
            <div class="card-content">
              <h3>Artist Verification</h3>
              <p>Coming soon - Test the complete artist verification process</p>
            </div>
            <div class="card-label">Coming Soon</div>
          </div>

          <div class="test-card coming-soon">
            <div class="card-content">
              <h3>Payment Integration</h3>
              <p>Coming soon - Test payment flows and transactions</p>
            </div>
            <div class="card-label">Coming Soon</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeveloperPlayground',
  metaInfo: {
    title: 'Developer Playground'
  }
}
</script>

<style lang="scss" scoped>
.developer-playground {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
    }
  }

  .section {
    margin-bottom: 3rem;

    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(95, 105, 134, 0.3);
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
    }
  }

  .test-card {
    background: #282C47;
    border: 1px solid #353B49;
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    text-decoration: none;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;

    &:not(.coming-soon):hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      border-color: #5F6986;

      .card-arrow {
        transform: translateX(4px);
      }
    }

    &.coming-soon {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .card-content {
      flex: 1;

      h3 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9rem;
        line-height: 1.4;
      }
    }

    .card-arrow {
      color: #20C4F5;
      transition: transform 0.3s ease;
    }

    .card-label {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: rgba(95, 105, 134, 0.3);
      padding: 0.25rem 0.75rem;
      border-radius: 12px;
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style> 